import React, { useRef, useEffect } from 'react';

const StatusIcon = ({ variant, customStyleIcon }) => {
  let contentIcon;
  const container = useRef(null);

  useEffect(() => {
    let animation;

    const loadAnimation = async () => {
      if (variant === 'success') {
        const [lottie, animationData] = await Promise.all([
          import('lottie-web'),
          import('../animations/SuccessState.json')
        ]);

        animation = lottie.default.loadAnimation({
          container: container.current,
          animationData: animationData.default,
          renderer: 'svg',
          loop: false,
          autoplay: true,
        });
      }
    };

    loadAnimation();

    return () => {
      if (animation) {
        animation.destroy(); // Clean up animation on component unmount
      }
    };
  }, [variant]);

  if (variant === 'negative') {
    contentIcon = (
      <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 144.296 144.296">
        {/* SVG paths for negative icon */}
      </svg>
    );
  } else if (variant === 'warning') {
    contentIcon = (
      <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 144.296 144.296">
        {/* SVG paths for warning icon */}
      </svg>
    );
  } else if (variant === 'success') {
    contentIcon = <div ref={container} />;
  } else if (customStyleIcon) {
    contentIcon = (
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={customStyleIcon.width}
        height={customStyleIcon.height}
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M12.3125 16V12M12.3125 8H12.3225M22.3125 12C22.3125 17.5228 17.8353 22 12.3125 22C6.78965 22 2.3125 17.5228 2.3125 12C2.3125 6.47715 6.78965 2 12.3125 2C17.8353 2 22.3125 6.47715 22.3125 12Z"
          stroke={customStyleIcon.color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    contentIcon = null;
  }

  return contentIcon;
};

export default StatusIcon;
