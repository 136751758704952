import React, { useEffect, useState } from 'react';
import theme from '../../theme/Theme';
import Typography from '../typography/Typography';
import Spacing from '../../styles/spacing/Spacing';
import Box from '../box/Box';
import classes from './MobileMenu.module.scss';
import router from 'next/router';
import Logout from '../../icons/Logout';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import Profile from '../../icons/Profile';
import Business from '../../icons/Business';
import Verification from '../../icons/Verification';
import Inbox from '../../icons/Inbox';
import Settings from '../../icons/Settings';
import Help from '../../icons/Help';
import Language from '../../icons/Language';
import { setAccountTypeBusiness, setAccountTypePersonal, updateCustomer } from '../../../redux/business/actions';
import { ACTIONS, Analytics } from '../../../settings/analytics';
import { useDispatch } from 'react-redux';
import { Skeleton } from '@material-ui/lab';
import Loader from '../loader/Loader';

const MobileMenu = ({
    handleLogout,
    setAccountMenuOpen
}) => {
    const dispatch = useDispatch();
    const customer = useSelector((state) => state.customer);
    const business = useSelector((state) => state.business);
    const { t } = useTranslation('toolbar-profile');
    const [bothAccount, setBothAccount] = useState(false);
    const [hasPersonal, setHasPersonal] = useState(false);
    const [hasBusiness, setHasBusiness] = useState(false);
    const [switchAccLoading, setSwitchAccLoading] = useState(true)
    const [switching, setSwitching] = useState(false);
    const [switchLabel, setSwitchLabel] = useState('');

    useEffect(() => {
        getBusinessInfo();
    }, [customer]);

    const getBusinessInfo = async () => {
        let label = '';
        
        setHasBusiness(customer.is_business_ac === '1');
        setHasPersonal(customer.is_personal_ac === '1');
        setBothAccount(customer.is_business_ac === '1' && customer.is_personal_ac === '1');

        if (customer.is_business_ac === '1') {
            label = business?.account_type === 'personal' ? t('business') : t('personal');
        }
        if (customer.is_personal_ac === '1') {
            label = business?.account_type === 'business' ? t('personal') : t('business');
        }
        if (customer.is_business_ac === '1' && customer.is_personal_ac === '1') {
            label = business?.account_type === 'personal' ? t('switch-business') : t('switch-personal');
        }

        if (label === '') { // edge case to avoid showing empty value
            label = business?.account_type === 'personal' ? t('business') : t('personal');
            // we should add logs here to track if it is going in this block of code
        }

        setSwitchLabel(label);
        setSwitchAccLoading(false)
    }

    const ACCOUNT_MENU = [
        {
            label: 'account_details',
            path: "/account-details",
            icon: <Profile />
        },
        {
            label: "verification_and_limit",
            path: business?.account_type === 'personal' ? '/verification' : '/verification/business-levels',
            icon: <Verification />
        },
        {
            name: 'switch-accounts',
            label: switchLabel,
            icon: <Business />
        },
        {
            label: "inbox",
            path: "/inbox",
            icon: <Inbox />
        },
        {
            label: "settings",
            path: "/settings",
            icon: <Settings />
        },
        {
            label: "Help centre",
            icon: <Help />,
            path: '/customer-help'
        },
        {
            label: "Language",
            icon: <Language />,
            path: '/settings/language'
        }
    ]

    const getFullName = () => {
        const { cus_firstname, cus_lastname } = customer;
        if (business?.account_type === 'business' && business.name) {
            return business.name;
        } else if (cus_firstname && cus_lastname) {
            return `${cus_firstname || ""} ${cus_lastname || ""}`;
        } else {
            return 'RemitBee user';
        }
    }

    const getUniqueId = () => {
        if (business?.account_type === 'business') {
            return business.business_unique_id || '-';
        } else if (customer) {
            return customer.cus_unique_id || '-';
        } else return '-';
    }

    const customerUpdate = async () => {
        // throw 'STOP';
        setSwitching(true);
        //if (setAccountMenuOpen) setAccountMenuOpen(false);
        if (business?.account_type === 'personal' && !hasBusiness) {
            Analytics.track(ACTIONS.OPEN_BUSINESS_ACCOUNT, {});
            router.push('/business-account');
        } else if (business?.account_type === 'business' && !hasPersonal) {
            Analytics.track(ACTIONS.OPEN_PERSONAL_ACCOUNT, {});
            router.push('/create-personal');
        } else if (business?.account_type === 'personal' && bothAccount) {
            const { success, data } = await updateCustomer({ "last_active_ac": "BUSINESS" })
            if (success && data && data.updateCustomerPersonalInfo) {
                dispatch(setAccountTypeBusiness());
                Analytics.track(ACTIONS.SWITCH_TO_PERSONAL_ACCOUNT, {});
                router.push('/business-account/dashboard')
            }
        } else if (business?.account_type === 'business' && bothAccount) {
            const { success, data } = await updateCustomer({ "last_active_ac": "PERSONAL" })
            if (success && data && data.updateCustomerPersonalInfo) {
                Analytics.track(ACTIONS.SWITCH_TO_BUSINESS_ACCOUNT, {});
                dispatch(setAccountTypePersonal())
                router.push('/dashboard')
            }
        }
    }

    const handleMenuClick = (item: any) => {
        if (item.name === 'switch-accounts') {
            customerUpdate();
            return
        }
        router.push(item.path);
    }

    const renderSkeleton = (list) => {
        return (
            <ul className={classes['rb-mobileMenu-menu']}>
                {list && list.map((item,i) => (
                    <li key={i}>
                        <Skeleton variant="rect" width={'100%'} height={30} />
                    </li>
                ))}
            </ul>
        )
    }

    const renderMenu = (menu: any[]) => {
        return (
            <ul className={classes['rb-mobileMenu-menu']}>
                {menu && menu.map(item => (
                    <li onClick={() => handleMenuClick(item)} key={item?.path}>
                        {item?.icon}
                        <Typography variant='label1' color={theme.palette.primary.navy}>{t(item.label)}</Typography>
                    </li>
                ))}
            </ul>
        )
    }

    if (switching) return <Loader />

    return (
        <div className={classes['rb-mobileMenu-root']}>
            <Typography variant='h2' weight='semibold' color={theme.palette.primary.navy}>{getFullName()}</Typography>
            <Spacing variant='titleToDivider' />
            <Typography variant='body2' color={theme.palette.primary.navy}>{`Account ID: `}<span id='account-number'>&nbsp;{getUniqueId()}</span></Typography>
            <Spacing variant='betweenSectionToInputFields' />
            {switchAccLoading ? renderSkeleton(ACCOUNT_MENU) : renderMenu(ACCOUNT_MENU)}
            <Spacing variant='contentToCta' />
            <Box display='flex' alignItems='center' className={classes['rb-mobileMenu-logout']} onClick={handleLogout}>
                <Logout width={21} height={21} color={theme.palette.input.body} />
                <Typography variant='label1' color={theme.palette.primary.navy}>{'Logout'}</Typography>
            </Box>
            <Spacing variant='contentToCta' />
            <Spacing variant='contentToCta' />
        </div>
    );
};

export default MobileMenu;
