import React, {  useRef, useEffect } from 'react';
import classes from './Menu.module.scss';

const MenuDropdown: React.FC<{options: any;
    open: boolean;
    onClose: () => void;
    id: string;
    setOpen: any;
    parentRef: any;
    position: { top?: string, left?: string, right?: string, bottom?: string }
    style?: object;}> = ({ id, open, setOpen, onClose, options, parentRef, position = {}, style = {} }) => {
    const menuRef = useRef<HTMLDivElement>(null);
    let customStyle = { ...position, ...style }

    const handleClickOutside = (event: { target: any; }) => {
        if ((menuRef.current && !menuRef.current.contains(event.target)) && (parentRef && !parentRef.current.contains(event.target))) {
            setOpen(false);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
            setOpen(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    if (!open) return null
    return (
        <div id={id} ref={menuRef} onClick={onClose} className={classes['rb-menu-wrapper']} style={customStyle}>
            <ul className={classes['rb-list-item-container']}>
                {options}
            </ul>
        </div>
    );
};

export default MenuDropdown;
